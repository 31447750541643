.events-section {
  display: block;
  min-height: 80vh;
  padding: 2rem;
  background-color: #fff;
}

.events-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #e40000;
  margin-bottom: 1.5rem;
}
