.s-card {
  width: 20%;
  text-align: center;
  padding: 0px 0px 20px 0px;
  background-color: rgb(32, 31, 31);
  border-radius: 10px;
}

.s-card > img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center -50px;
}

.s-card > h3 {
  padding: 5px 0px;
  color: rgb(221, 216, 216);
}

.s-card > p {
  color: gray;
  line-height: 2;
  padding: 10px;
}

.s-card > a {
  padding: 10px 15px;
  background-color: red;
  border-radius: 25px;
}

@media only screen and (max-width: 1200px) {
  .s-card {
    width: 30%;
  }
}

@media only screen and (max-width: 900px) {
  .s-card {
    width: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .s-card {
    width: 70%;
  }
}
