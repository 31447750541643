.blog-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  min-height: 70vh;
}

@media (max-width: 768px) {
  .blog-container {
    padding: 0.5rem;
  }

  .blog-post {
    display: block;
  }

  .blog-post-content {
    padding: 1rem;
  }
}
