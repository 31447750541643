.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: red;
  z-index: 2;
  transform-origin: 0%;
}

.speciality {
  background-color: black;
  padding: 20px 0px;
}

.speciality > h3 {
  color: red;
  text-align: center;
  font-size: 40px;
  padding: 10px 0px;
}

.speciality-cards {
  color: white;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}

.about-section {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  padding: 55px 100px;
  background-color: black;
  color: white;
}

.about-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center -50px; */
}

.about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}

.about-us > div {
  text-align: center;
}

.about-us > div > h3 {
  color: red;
  font-size: 40px;
  padding: 10px 0px;
}

.about-us > div > p {
  color: grey;
  font-size: 20px;
  line-height: 1.5;
  padding-bottom: 15px;
}

.gallery-videos {
  background-image: url("../../assets/images/texture-background.jpg");
  padding: 30px 0px;
}

.gallery {
  margin-bottom: 55px;
}

.gallery > h3 {
  color: red;
  font-size: 40px;
  padding: 10px 0px;
  text-align: center;
}

.videos > h3 {
  color: red;
  font-size: 40px;
  padding: 10px 0px;
  text-align: center;
}

.videos > div {
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr 1fr; */
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}

/* .contact {
    padding: 15px;
    background-image: url("../../assets/images/texture-background.jpg");
}

.contact>h3 {
    color: red;
    font-size: 40px;
    padding: 10px 0px;
    text-align: center;
}

.details {
    color: white;
    padding: 30px;
}

.details-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
}

.detail {
    display: flex;
    column-gap: 5px;
    padding-bottom: 30px;
}

.detail>div>h3 {
    font-size: 20px;
    padding-bottom: 10px;
}

.detail>div>p {
    color: gray;
    font-size: 17px;
}

.map:hover {
    color: rgb(185, 84, 84);
}

.form {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 25px;
    padding: 30px;
}

.form>input {
    padding: 10px;
    border-radius: 5px;
    opacity: 0.7;
}

.form>textarea {
    padding: 10px;
    border-radius: 5px;
    height: 100px;
    opacity: 0.7;
}

.form>button {
    padding: 5px 10px;
    background-color: red;
    border-radius: 30px;
    border: 0px solid transparent;
    width: 25%;
    margin: auto;
} */

/* @media only screen and (max-width:1000px) {
    .details-form {
        grid-template-columns: 1fr;
    }

} */

@media only screen and (max-width: 1200px) {
  .s-card {
    width: 30%;
  }
  .about-section {
    display: block;
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 900px) {
  .s-card {
    width: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .s-card {
    width: 70%;
  }
}
