.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: red;
  color: white;
  z-index: 100;
}

.logo {
  display: flex;
}

.logo>a>img {
  width: 60px;
  height: 60px;
}

.nav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  list-style: none;
}

.nav-list>li {
  font-size: 18px;
}

.enquire-now {
  background-color: black;
  color: white;
  padding: 10px 15px;
  border-radius: 25px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.hamburger-menu {
  display: none;
}

.nav-item:hover {
  border-bottom: 2px solid white;
  transition: width 0.3s ease-in-out;
}

.active {
  border-bottom: 2px solid white;
}

/* Hide nav-list and enquire-now by default on smaller screens */
@media only screen and (max-width: 1100px) {
  .nav-list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: red;
    padding: 20px 0;
  }

  .nav-list.show {
    display: flex;
    /* Show nav-list when toggled */
  }

  .enquire-now {
    display: none;
    position: absolute;
    width: 80%;
    left: 10%;
    bottom: -20px;
  }

  .enquire-now.show {
    display: none;
    /* Show enquire-now when toggled */
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}