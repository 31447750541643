.footer {
    background-color: red;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
}

.card {
    width: fit-content;
    height: fit-content;
    /* background-color: rgb(238, 238, 238); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 25px 25px; */
    gap: 20px;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}

/* for all social containers*/
.socialContainer {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: .3s;
}

/* instagram*/
.containerOne:hover {
    background-color: #d62976;
    transition-duration: .3s;
}

/* twitter*/
.containerTwo:hover {
    background-color: #00acee;
    transition-duration: .3s;
}

/* linkdin*/
.containerThree:hover {
    background-color: #0072b1;
    transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
    background-color: #128C7E;
    transition-duration: .3s;
}

/* YouTube */
.containerFive:hover {
    background-color: #FF0000;
    transition-duration: 0.3s;
}


.socialContainer:active {
    transform: scale(0.9);
    transition-duration: .3s;
}

.socialSvg {
    width: 17px;
}

.socialSvg path {
    fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
}

@media only screen and (max-width: 450px) {
    .copyright {
        display: none;
    }

    .footer {
        justify-content: center;
    }

}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}