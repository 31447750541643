.contact {
  padding: 15px;
  background-image: url("../../assets/images/texture-background.jpg");
}

.contact > h3 {
  color: red;
  font-size: 40px;
  padding: 10px 0px;
  text-align: center;
}

.details {
  color: white;
  padding: 30px;
}

.details-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.detail {
  display: flex;
  column-gap: 5px;
  padding-bottom: 30px;
}

.detail > div > h3 {
  font-size: 20px;
  padding-bottom: 10px;
}

.detail > div > p {
  color: gray;
  font-size: 17px;
}

.map:hover {
  color: rgb(185, 84, 84);
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 25px;
  padding: 30px;
}

.form > input {
  padding: 10px;
  border-radius: 5px;
  opacity: 0.7;
}

.form > textarea {
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  opacity: 0.7;
}

.form > button {
  padding: 5px 10px;
  background-color: red;
  border-radius: 30px;
  border: 0px solid transparent;
  width: 25%;
  margin: auto;
}
@media only screen and (max-width: 1000px) {
  .details-form {
    grid-template-columns: 1fr;
  }
}
