.event-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  background: #c0bdbd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 2rem;
}

.event-card.reverse {
  flex-direction: row-reverse;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
}

.event-image {
  flex: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-content {
  flex: 1;
  padding: 1rem;
}

.event-title {
  font-size: 1.5rem;
  color: #333;
  margin: 0 0 0.5rem;
}

.event-date {
  font-size: 0.9rem;
  color: #3b3a3a;
  margin-bottom: 1rem;
  font-style: italic;
}

.event-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.event-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #e40000;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
  text-align: center;
}

.event-button:hover {
  background-color: #c30000;
}

@media (max-width: 768px) {
  .event-card {
    flex-direction: column;
  }

  .event-card.reverse {
    flex-direction: column;
  }
}
