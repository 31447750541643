.blog-post {
  display: grid;
  grid-template-columns: 30% 50%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  margin-bottom: 2rem;
  overflow: hidden;
}

.blog-post img {
  width: 100%;
  height: 100%;
}

.blog-post-content {
  padding: 1.5rem;
}

.blog-post-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #e40000;
}

.blog-post-text {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

.read-more {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #e40000;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.read-more:hover {
  background-color: #c30000;
}

@media (max-width: 768px) {
  .blog-post {
    display: block;
  }

  .blog-post-content {
    padding: 1rem;
  }
}