* {
    box-sizing: border-box;
}

.testimonial-section {
    text-align: center;
    padding: 20px 250px;
    /* width: 80%; */
    /* max-width: 600px; */
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.7);
    /* border-radius: 8px; */
    color: #fff;
    font-family: Arial, sans-serif;
}

.testimonial-section h2 {
    font-size: 24px;
    color: #ff0000;
    margin-bottom: 20px;
}

.testimonial {
    position: relative;
    padding: 20px;
    color: #ddd;
}

.testimonial p {
    font-size: 18px;
    line-height: 1.6;
    margin: 0 30px;
}

.quote-left,
.quote-right {
    font-size: 40px;
    color: #fff;
    position: absolute;
}

.quote-left {
    top: 0;
    left: 10px;
}

.quote-right {
    bottom: 0;
    right: 10px;
}

.author {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.author img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.author span {
    font-size: 16px;
    color: #fff;
}

/* Custom styling for dots */
.react-multi-carousel-dot button {
    background-color: #fff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0.5;
    margin: 0 4px;
}

.react-multi-carousel-dot--active button {
    opacity: 1;
}

@media only screen and (max-width:1000px) {
    .testimonial-section {
        padding: 20px 150px;
    }
}


@media only screen and (max-width:800px) {
    .testimonial-section {
        padding: 20px 50px;
    }
}

@media only screen and (max-width:400px) {
    .testimonial-section {
        padding: 20px 20px;
    }
}