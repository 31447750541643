.addressMap {
  background-color: black;
  padding: 20px 10px;
}
.addressMap > h3 {
  color: red;
  font-size: 35px;
  text-align: center;
  padding: 10px 0px;
}
.gmaps {
  display: grid;
  grid-template-columns: 40% 40%;
  gap: 40px;
  justify-content: center;
  align-items: center;
}
.gmap {
  text-align: center;
  /* width: 100%; */
}

.gmap > h3 {
  color: white;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1500px) {
  .gmaps {
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
  }
  /* .gmap {
    width: 80%;
  } */
}
