/* .gallery-videos {
  background-image: url("../../assets/images/texture-background.jpg");
  padding: 30px 0px;
} */
.temp {
  background-color: black;
  padding: 30px 0px;
}
/* .gallery {
  margin-bottom: 55px;
}

.gallery > h3 {
  color: red;
  font-size: 40px;
  padding: 10px 0px;
  text-align: center;
} */
