.singleblog-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  background: white;
  min-height: 70vh;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.singleblog-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #e40000;
}

.singleblog-meta {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 1.5rem;
}

.singleblog-content img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  object-position: top;
}

.singleblog-content h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 20px;
  padding: 20px 0px;
}

.singleblog-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
}

.back-button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  background-color: #e40000;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.back-button:hover {
  background-color: #c30000;
}

@media (max-width: 768px) {
  .singleblog-container {
    padding: 1rem;
  }
}